<div class="container p-1 p-md-3 p-lg-5" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
    <div class="row p-2 justify-content-center align-items-center text-center">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 62%; left: 45%;">
                CONTATTI
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    CONTATTI
                </span>   
            </div>
        </div>
        <div class="col-12 text-center mt-4" style="font-weight: bold; font-size: large;">
            Via Giotto, 34 - 50032 - Borgo San Lorenzo (FI) - Italy
        </div>
        <div class="col-12 mt-3">
            <p>Per tutti gli uffici: <br><strong>tel : 055 84 94 118 </strong> </p>
            <p> Per gli Ambulatori della Fondazione: <br><strong>tel : 055 84 55 299 </strong> </p>
            <p> Per i Servizi Funebri della Fondazione: <br> <strong>tel : 055 84 02 758 </strong> </p>
            <p> Per tutti gli uffici: <br> <strong> fax : 055 84 95 777 </strong> </p>
            <p> Per tutti gli uffici: <br> <strong> e-mail : home&#64;misericordia.net </strong></p>
            <p> Posta elettronica sertificata <br><strong> e-mail: misericordiabsl&#64;legalmail.it </strong></p>
        </div>
        <div class="col-12 text-center office mt-4">
            <div class="p-3">
                <strong style="font-size: large;">Ufficio Segreteria & Amministrazione</strong><br>
                <strong>Orario : dal LUNEDI al SABATO ore 9,00 – 12,00 </strong><br>
                <strong>Segreteria:</strong> Laura Innocenti e Donatella Giuliani <br>
                <strong>Amministrazione:</strong> Loredana Generini
            </div>
        </div>
        <div class="col-12 text-center office">
            <div class="p-3">
                <strong style="font-size: large;">Ufficio Trasporti</strong><br>
                <strong>Orario : dal LUNEDI al VENERDI ore 8,00 – 20,00</strong><br>
                (l’accoglienza è prevista solo in orario segreteria o in caso di NON uscita del 118)<br>
            </div>
        </div>
        <div class="col-12 text-center office">
            <div class="p-3">
                <strong style="font-size: large;">Ufficio di Presidenza</strong><br>
                Orario : Il Provveditore riceve su appuntamento. Telefonare in segreteria.<br>
                <strong>Provveditore:</strong> Piero Margheri
            </div>
        </div>
    </div>
</div>
