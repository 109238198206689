import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageViewerComponent } from "../image-viewer/image-viewer.component";

@Component({
  selector: 'app-modal-image-viewer',
  standalone: true,
  imports: [
    CommonModule,
    ImageViewerComponent
],
  templateUrl: './modal-image-viewer.component.html',
  styleUrl: './modal-image-viewer.component.scss'
})
export class ModalImageViewerComponent {
  //isImage: boolean;
  imageId: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: string) {
    //this.isImage = this.checkIfImage(data);
    this.imageId = parseInt(data);
  }

  checkIfImage(fileName: string): boolean {
    const ext = fileName.split('.').pop()?.toLowerCase();
    return ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif';
  }
}
