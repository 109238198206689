import { Component } from '@angular/core';
import { ApiResponse } from '../../../interfaces/api-response';
import { Connect } from '../../../classes/connect';
import { Router } from '@angular/router';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ConnectServerService } from '../../../services/connect-server.service';
import { PopupDialogService } from '../../../services/popup-dialog.service';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {
  isSent: boolean = false;
  validEmail: boolean = true;

  recoverPswForm = new FormGroup({
    email: new FormControl('', Validators.required),
  })

  constructor(private router: Router, private connectServerService: ConnectServerService,
    private popupDialogService: PopupDialogService
  ) { }

  send() {
   //Send the request to the server
   const email_val = this.recoverPswForm.get('email')?.value;
     this.validEmail = true;
     this.isSent = true;
     this.connectServerService.postRequest<ApiResponse<{}>>(Connect.urlServerLaraApi, 'forgot-password', {
       email: email_val
     }).subscribe(
       (esito: ApiResponse<{}>) => {
         this.popupDialogService.popupDialog(esito);
         this.router.navigate(['home']);
       }
     )

  }

  goLogin() {
    this.router.navigate(['login']);
  }

  checkEmail() {
    const email_val = this.recoverPswForm.get('email')?.value;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailRegex.test(email_val!)) {
      this.validEmail = true;
      this.isSent = true;
      this.recoverPswForm.setErrors(null);
    }
    else {
      this.validEmail = false;
      this.recoverPswForm.setErrors({invalid: true});
    }
  }
}
