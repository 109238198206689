import { Component } from '@angular/core';

@Component({
  selector: 'app-archive-artistic',
  standalone: true,
  imports: [],
  templateUrl: './archive-artistic.component.html',
  styleUrl: './archive-artistic.component.scss'
})
export class ArchiveArtisticComponent {

}
