<div class="container p-1 p-md-3 p-lg-5" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
    <div class="row align-items-center justify-content-center p-2">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 62%; left: 40%;">
                RICHIEDI ACCESSO
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    RICHIEDI ACCESSO
                </span>
            </div>
        </div>

        <!-- SPIEGAZIONE PAGINA -->
        <div class="col-12 text-center mt-2">
            Richiedi accesso per ottenere la visualizzazione completa degll'archivio artistico.<br>
            <span style="font-size: small;">tutti i campi sono obbligatori</span>
        </div>

        <!-- FORM -->
        <div class="col-12 col-sm-10 col-6-md col-4-lg mt-4">
            <div class="card">
                <div class="card-body">
                    <form class="formSection row align-items-center" [formGroup]="signupForm">
                        <div class="form-group col-12 col-lg-6">
                            <label for="name" class="form-label section">Nome</label>
                            <input type="text" class="form-control" value="" formControlName="name"
                                (input)="checkSignupForm()" [class.is-invalid]="signupForm.get('name')?.invalid && 
                            (signupForm.get('name')?.touched || signupForm.get('name')?.dirty)">
                        </div>
                        <div class="form-group col-12 col-lg-6">
                            <label class="form-label section">Cognome</label>
                            <input type="text" class="form-control" value="" formControlName="surname"
                                (input)="checkSignupForm()" [class.is-invalid]="signupForm.get('surname')?.invalid && 
                            (signupForm.get('surname')?.touched || signupForm.get('surname')?.dirty)">
                        </div>
                        <div class="form-group col-12 col-lg-6">
                            <label class="form-label section">Email</label>
                            <input type="text" class="form-control mb-1" value="" formControlName="email"
                                (input)="checkEmail()" [class.is-invalid]="signupForm.get('email')?.invalid &&
                                (signupForm.get('email')?.touched || signupForm.get('email')?.dirty)">
                        </div><br>
                        <div class="form-group col-12 col-lg-6">
                            <label for="password2" class="form-label section">
                                Nuova Password
                            </label>
                            <div class="input-group">
                                <input [type]="type1" id="password" formControlName="password" class="form-control"
                                    (input)="checkPassword()" [class.is-invalid]="signupForm.get('password')?.invalid &&
                                    (signupForm.get('password')?.dirty || signupForm.get('password')?.touched)">
                                <button class="btn btn-outline-dark">
                                    <i class="bi bi-eye icon"
                                        [ngClass]="{'bi-eye': !toggled1, 'bi-eye-slash': toggled1}" id="togglePassword"
                                        (click)="seePassword('password1')">
                                    </i>
                                </button>
                            </div>
                            <div *ngIf="errorMessage && !validPassword" class="mt-1" id="email"
                                style="color: rgb(255, 0, 0); font-size: small;">
                                <small>*La password deve essere lunga almeno 8 caratteri, contenere una
                                    maiuscola ed un simbolo speciale "[!&#64;#$%^&*(),.?":|&lt;></small>
                            </div>
                        </div>
                        <div class="form-group col-12 col-lg-6">
                            <label for="password_confirmation" class="form-label section">
                                Ripeti Password
                            </label>
                            <div class="input-group">
                                <input [type]="type2" id="password_confirmation" formControlName="password_confirmation"
                                    class="form-control" (input)="checkPassword()"
                                    [class.is-invalid]="signupForm.get('password_confirmation')?.invalid &&
                                    (signupForm.get('password_confirmation')?.dirty || signupForm.get('password_confirmation')?.touched)">
                                <button class="btn btn-outline-dark">
                                    <i class="bi bi-eye icon"
                                        [ngClass]="{'bi-eye': !toggled2, 'bi-eye-slash': toggled2}" id="togglePassword"
                                        (click)="seePassword('password2')">
                                    </i>
                                </button>
                            </div>
                            <div *ngIf="errorMessage && !equalPassword" class="mt-1" id="email"
                                style="color: rgb(255, 0, 0); font-size: small;">
                                <small>*Le due password sono differenti</small>
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="description" class="form-label section">
                                Informazioni Aggiuntive
                            </label>
                            <textarea class="form-control" placeholder="Inserisci il motivo della richiesta"
                                id="description" formControlName="description" rows="3"></textarea>
                        </div>
                    </form>

                    <div class="container mt-4">
                        <div class="row justify-content-center mt-3" style="border-top: 1px solid lightgray;">
                            <div class="col-12 mt-3 text-center">
                                <div *ngIf="signupForm.invalid" id="email"
                                    style="color: gray;">
                                    <span style="font-size: small;">Completa correttmente tutti i campi</span>
                                </div>
                            </div>
                            <div class="col-12 col-md-4 text-center mt-1">
                                <button class="btn btn-outline-dark" type="submit" [disabled]="!signupForm.valid"
                                    (click)="onSubmit()">
                                    Richiedi
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>