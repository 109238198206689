<div class="container p-5" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
    <div class="row p-2 justify-content-center align-items-center text-center">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 62%; left: 38%;">
                RECUPERA PASSWORD
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    RECUPERA PASSWORD
                </span>
            </div>
        </div>
    </div>

    <div class="col-12 text-center p-2 p-md-3 p-lg-4">
        <span>Verrà inviata una email all'indirizzo inserito, con il link per reimpostare la password</span>
    </div>


    <div class="row justify-content-center align-items-center">
        <div class="col-12 col-md-8 col-lg-6 col-xxl-4">
            <div class="card p-2">
                <div class="card-body">
                    <form [formGroup]="recoverPswForm" class="has-validation" style="margin: 0 auto;">
                        <div class="form-group">
                            <label for="email" class="sr-only" style="font-weight: bold;">Email</label>
                            <input type="email" name="email" id="email" formControlName="email" class="form-control"
                                placeholder="" aria-describedby="emailFeedback" [class.is-invalid]="recoverPswForm.invalid && 
                                (recoverPswForm.dirty || recoverPswForm.touched)" required (input)="checkEmail()">
                        </div>
                        <div *ngIf="!validEmail" id="email" class="mt-1" style="color: rgb(255, 0, 0);">
                            <span style="font-size: small;">Email non valida</span>
                        </div>
                    </form>
                </div>
                <div class="card-footer text-center mt-2" style="background-color: white;">
                    <button class="btn btn-outline-dark mt-2" (click)="send()"
                        [disabled]="recoverPswForm.invalid">Invia</button>
                </div>
            </div>
        </div>
    </div>
</div>