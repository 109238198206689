<div class="container-fluid border-top footer" style="min-width: 380px;">
    <div class="row mt-3">
        <div class="col-12 col-sm-4 text-center mb-2">
            <img class="navbar-logo mt-2" src="assets/img/logo.png">
        </div>
        <div class="col-12 col-sm-4 text-center mb-2">
            <div style="max-width: 300px; margin: 0 auto; color: white; font-size: small;">
                <strong>Misericordia Borgo San Lorenzo</strong> <br> Via Giotto, 34 - 50032 <br> Borgo San Lorenzo (FI)
                Italy <br> tel. 055 84
                94 118
            </div>
        </div>
        <div class="col-12 col-sm-4 text-center mb-2">
            <ul class="list-unstyled">
                <li class="mb-1">
                    <h4 style="font-weight: bold; font-size: small; color: white;">TROVACI SU</h4>
                </li>
                <li class="mb-1 text-center">
                    <i class="icona bi bi-facebook" style="font-size: 1.6rem;"
                        (click)="onClick('https://www.facebook.com/people/Misericordia-Borgo-San-Lorenzo/100063703517940/')">
                    </i>
                    <i class="icona bi bi-house-fill" (click)="onClick('http://www.misericordia.net/')"
                        style="font-size: 1.6rem; margin-left: 1rem;">
                    </i>
                </li>
            </ul>
        </div>
    </div>
</div>

<div class="powered container-fluid d-flex border-top justify-content-center">
    <div class="mt-2 mb-1" style="color: white; font-size: small;">
        Creato da <a href="https://wallnet.it/" style="color: white;">Wallnet</a>
    </div>
</div>