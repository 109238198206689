import { Component } from '@angular/core';

@Component({
  selector: 'app-article-artistic',
  standalone: true,
  imports: [],
  templateUrl: './article-artistic.component.html',
  styleUrl: './article-artistic.component.scss'
})
export class ArticleArtisticComponent {

}
