<div class="container p-1 p-md-3 p-lg-5" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
    <div class="row p-2 justify-content-center align-items-center">
        <!-- INTESTAZIONE PAGINA -->
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 62%; left: 40%;">
                INVENTARIO ARTISTICO
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    INVENTARIO ARTISTICO
                </span>
            </div>
        </div>

        <!-- FILTRI -->
        <div class="col-12 col-sm-10 col-md-8 col-lg-6 p-3 p-lg-4">
            <label for="categoryFilter mb-2" style="font-weight: bold; font-size: large;">Filtra Categorie</label>
            <select class="form-select" id="categoryFilter" [formControl]="categoryFilter" (change)="getInventoryFiltered()">
                <option [ngValue]="null" selected>Tutte le Categorie</option>
                <option *ngFor="let category of categories" [ngValue]="category.id">{{category.title}}</option>
            </select>
        </div>

        <!-- LISTA ARTICOLI -->
        <div *ngFor="let element of inventoryArtistic" class="col-12 col-md-10 pt-3">
            <div class="titleBorder ps-3 d-flex justify-content-between align-items-center"
                (click)="openClose(element)">
                <div class="d-flex align-items-center">
                    <i class="bi bi-folder me-2"></i>
                    <span class="category">{{element.title}}</span>
                    <i *ngIf="(element.isOpen && element.listArticles.length)" class="bi bi-caret-up-fill ms-2"></i>
                    <i *ngIf="(!element.isOpen && element.listArticles.length)" class="bi bi-caret-down-fill ms-2"></i>
                </div>
                <span class="me-2" style="color: gray; font-style: italic; font-size: small;">Totale:
                    {{element.listArticles.length}}</span>
            </div>
            <div [ngClass]="{'open': element.isOpen, 'closed': !element.isOpen}" class="content-wrapper row ps-3 pe-3 ps-md-4 pe-md-4 ps-lg-5 pe-lg-5">
                <div *ngFor="let article of element.articles" class="col-12 contentBorder">
                    <span><a href="archivioArtistico/scheda/{{article.id}}">{{article.title}}</a></span><br>
                    <span>{{article.code}},</span> <span class="ms-2">{{article.subcategory}}</span>
                </div>
            </div>
        </div>
    </div>
</div>