import { Injectable } from '@angular/core';
import { InventoryHistorical } from '../pages/inventory/interfaces/inventory-historical';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor() { }

  //{category: {name: string, id: number}, articles: InventoryHistorical[]}
  inventoryHistorical: any[] = [
    { category: {id: 1, name: "Atti e Verbali"}, articles: [
      {
        id: 1,
        title: "Inventory Record 001",
        subcategory: "Electronics",
        code: "ELC-001",
        floor: "Ground",
        room: "101",
        rack: "Rack A",
        case: "Case 1"
      },
      {
        id: 2,
        title: "Inventory Record 002",
        subcategory: "Furniture",
        code: "FUR-001",
        floor: "First",
        room: "201",
        rack: "Rack B",
        case: "Case 2"
      },
      {
        id: 3,
        title: "Inventory Record 003",
        subcategory: "Documents",
        code: "DOC-001",
        floor: "Second",
        room: "301",
        rack: "Rack C",
        case: "Case 3"
      },
      {
        id: 4,
        title: "Inventory Record 004",
        subcategory: "Books",
        code: "BOK-001",
        floor: "Third",
        room: "401",
        rack: "Rack D",
        case: "Case 4"
      },
      {
        id: 5,
        title: "Inventory Record 005",
        subcategory: "Machinery",
        code: "MCH-001",
        floor: "Basement",
        room: "501",
        rack: "Rack E",
        case: "Case 5"
      }
    ]},
    { category: {id: 2, name: "Statuto"}, articles: [
        {
          id: 6,
          title: "Inventory Record 006",
          subcategory: "Tools",
          code: "TLS-001",
          floor: "Second",
          room: "302",
          rack: "Rack F",
          case: "Case 6"
        },
        {
          id: 7,
          title: "Inventory Record 007",
          subcategory: "Office Supplies",
          code: "OFF-001",
          floor: "First",
          room: "202",
          rack: "Rack G",
          case: "Case 7"
        },
        {
          id: 8,
          title: "Inventory Record 008",
          subcategory: "Clothing",
          code: "CLT-001",
          floor: "Ground",
          room: "102",
          rack: "Rack H",
          case: "Case 8"
        },
        {
          id: 9,
          title: "Inventory Record 009",
          subcategory: "Art",
          code: "ART-001",
          floor: "Third",
          room: "402",
          rack: "Rack I",
          case: "Case 9"
        },
        {
          id: 10,
          title: "Inventory Record 010",
          subcategory: "Jewelry",
          code: "JW-001",
          floor: "Basement",
          room: "502",
          rack: "Rack J",
          case: "Case 10"
        }
    ]},
    { category: {id: 3, name: "Contabilità"}, articles: [
        {
          id: 11,
          title: "Inventory Record 011",
          subcategory: "Medical Equipment",
          code: "MED-001",
          floor: "First",
          room: "203",
          rack: "Rack K",
          case: "Case 11"
        },
        {
          id: 12,
          title: "Inventory Record 012",
          subcategory: "Musical Instruments",
          code: "MUS-001",
          floor: "Second",
          room: "303",
          rack: "Rack L",
          case: "Case 12"
        },
        {
          id: 13,
          title: "Inventory Record 013",
          subcategory: "Sporting Goods",
          code: "SPT-001",
          floor: "Ground",
          room: "103",
          rack: "Rack M",
          case: "Case 13"
        },
        {
          id: 14,
          title: "Inventory Record 014",
          subcategory: "Vehicles",
          code: "VEH-001",
          floor: "Garage",
          room: "G01",
          rack: "Rack N",
          case: "Case 14"
        },
        {
          id: 15,
          title: "Inventory Record 015",
          subcategory: "Antiques",
          code: "ANT-001",
          floor: "Third",
          room: "403",
          rack: "Rack O",
          case: "Case 15"
        }
    ]}
  ]
}
