<div class="container-fluid p-0 d-flex" style="min-width: 380px; min-height: 80%;">
    <div id="bdSidebar" class="d-flex flex-column flex-shrink-0 offcanvas-lg offcanvas-start sidebar"
        style="border-right: 2px solid lightgrey;">
        <a href="#" class="navbar-brand"></a>
        <app-filter (searchFilter)="searchArticles($event)"></app-filter>
    </div>

    <div class="bg-light flex-fill">
        <div class="ps-4 mt-2 d-lg-none d-flex">
            <button class="btn btn-outline-dark" type="button" data-bs-toggle="offcanvas" data-bs-target="#bdSidebar"
                aria-controls="bdSidebar" aria-expanded="false" aria-label="Toggle navigation">
                <span class=""><i class="bi bi-filter-left"></i></span>
                <span class="ms-2">Espandi Filtri</span>
            </button>
        </div>
        <div class="ps-4 pe-4 pb-4 mb-2 pt-1 pt-md-2 pt-lg-3 row align-items-center justify-content-center" style="position: relative;">
            <div class="d-none d-md-block col-md-4">
            </div>
            <div class="col-12 text-center m-2">
                <span class="ps-2 pt-1 pb-1 pe-2" style="font-size: x-large; font-weight: 500; background-color: #1E73BE; border-radius: 10px; color: white;">
                    Archivio Storico
                </span>
            </div>
            <div class="col-12 text-center paginatorPosition" style="position: absolute;">
                <nav aria-label="Page navigation">
                    <ul class="pagination justify-content-center mb-1">
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0)" aria-label="Previous"
                                (click)="previousPage()">
                                <span aria-hidden="true" style="color: #1E73BE;">&laquo;</span>
                            </a>
                        </li>
                        <li class="page-item pageSelector" *ngFor="let i of pages"
                            [class.active]="currentPageIndex == i">
                            <a class="page-link pageSelector"
                                (click)="setCurrentPage(i)">{{i}}</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="javascript:void(0)" aria-label="Next"
                                (click)="nextPage()">
                                <span aria-hidden="true" style="color: #1E73BE;">&raquo;</span>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
            <div class="col-12 col-sm-6 col-md-4 text-center">
                <span class="subtitles" style="font-weight: bold;">Risultati totali: </span> 
                {{totalResults}}
            </div>
            <div class="col-12 col-sm-6 col-md-4 text-center">
                <span class="subtitles" style="font-weight: bold;">Risultati per pagina: </span>
                {{itemsPerPage}}
            </div>
            <div class="d-none d-md-block col-md-4 text-center">
                <span class="subtitles" style="font-weight: bold;">Pagine totali: </span>
                {{totalPages}}
            </div>
            <hr>
            <div class="container-fluid" style="max-width: 1100px;">
                <div class="row justify-content-center">
                    <div *ngIf="articles.length > 0; else noContentBlock" class="col-12">
                        <article *ngFor="let content of articles" class="postcard dark yellow ms-0 me-0">
                            <div class="postcard__img_link">
                                <img class="postcard__img" [src]="urlServerLara + content.src"
                                    alt="Image Title">
                            </div>
                            <div class="postcard__text">
                                <h1 class="postcard__title green">
                                    <div class="titleLink" (click)="navigate(content.id)">{{content.title}}</div>
                                </h1>
                                <div class="postcard__subtitle small">
                                    <time datetime="2020-05-25 12:00:00">
                                        <i class="fas fa-calendar-alt mr-2"></i>{{content.category.title}}
                                    </time>
                                </div>
                                <div class="postcard__bar"></div>
                                <div class="postcard__preview-txt">{{content.description}}</div>
                                <div class="postcard__tagbox mt-4 align-self-center">
                                    <span class="tag__item" (click)="navigate(content.id)">
                                        <i class="fas fa-tag mr-2">
                                        </i>Vedi Articolo
                                    </span>
                                </div>
                            </div>
                        </article>
                    </div>
                    <ng-template #noContentBlock>
                        <div class="col-12 text-center mt-5">
                            <span style="font-size: large; font-weight: bold;">La ricerca non ha prodotto risultati</span>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- CARD VECCHIA -->
<!-- <div class="row justify-content-center">
                    <div *ngFor="let content of articles.paginatedArticles, let i = index"
                        class="col-lg-6 col-xxl-4 col-xs-12 col-sm-6 col-md-12 mb-3">
                        <div *ngIf="i < articles.filteredArticles.length" class="card">
                            <img src="{{content.src[0]}}" class="card-img-top">
                            <div class="card-body">
                                <h5 class="card-title"><strong>{{content.title}}</strong></h5>
                                <p class="card-text">{{content.description}}</p>
                            </div>
                            <a [routerLink]="['/archivio/articolo', content.id]" class="btn btn-primary custom-button">Vedi Articolo</a>
                        </div>
                    </div>
                </div> -->


<!-- VARIANTI CARD NUOVE -->
<!-- <div class="container py-4">

    <article class="postcard dark blue">
        <a class="postcard__img_link" href="#">
            <img class="postcard__img" src="https://picsum.photos/1000/1000" alt="Image Title" />
        </a>
        <div class="postcard__text">
            <h1 class="postcard__title blue"><a href="#">Podcast Title</a></h1>
            <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                    <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
            </div>
            <div class="postcard__bar"></div>
            <div class="postcard__preview-txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, fugiat
                asperiores inventore beatae accusamus odit minima enim, commodi quia, doloribus eius! Ducimus nemo
                accusantium maiores velit corrupti tempora reiciendis molestiae repellat vero. Eveniet ipsam adipisci
                illo iusto quibusdam, sunt neque nulla unde ipsum dolores nobis enim quidem excepturi, illum quos!</div>
            <ul class="postcard__tagbox">
                <li class="tag__item"><i class="fas fa-tag mr-2"></i>Podcast</li>
                <li class="tag__item"><i class="fas fa-clock mr-2"></i>55 mins.</li>
                <li class="tag__item play blue">
                    <a href="#"><i class="fas fa-play mr-2"></i>Play Episode</a>
                </li>
            </ul>
        </div>
    </article>
    <article class="postcard dark red">
        <a class="postcard__img_link" href="#">
            <img class="postcard__img" src="https://picsum.photos/501/500" alt="Image Title" />
        </a>
        <div class="postcard__text">
            <h1 class="postcard__title red"><a href="#">Podcast Title</a></h1>
            <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                    <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
            </div>
            <div class="postcard__bar"></div>
            <div class="postcard__preview-txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, fugiat
                asperiores inventore beatae accusamus odit minima enim, commodi quia, doloribus eius! Ducimus nemo
                accusantium maiores velit corrupti tempora reiciendis molestiae repellat vero. Eveniet ipsam adipisci
                illo iusto quibusdam, sunt neque nulla unde ipsum dolores nobis enim quidem excepturi, illum quos!</div>
            <ul class="postcard__tagbox">
                <li class="tag__item"><i class="fas fa-tag mr-2"></i>Podcast</li>
                <li class="tag__item"><i class="fas fa-clock mr-2"></i>55 mins.</li>
                <li class="tag__item play red">
                    <a href="#"><i class="fas fa-play mr-2"></i>Play Episode</a>
                </li>
            </ul>
        </div>
    </article>
    <article class="postcard dark green">
        <a class="postcard__img_link" href="#">
            <img class="postcard__img" src="https://picsum.photos/500/501" alt="Image Title" />
        </a>
        <div class="postcard__text">
            <h1 class="postcard__title green"><a href="#">Podcast Title</a></h1>
            <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                    <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
            </div>
            <div class="postcard__bar"></div>
            <div class="postcard__preview-txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, fugiat
                asperiores inventore beatae accusamus odit minima enim, commodi quia, doloribus eius! Ducimus nemo
                accusantium maiores velit corrupti tempora reiciendis molestiae repellat vero. Eveniet ipsam adipisci
                illo iusto quibusdam, sunt neque nulla unde ipsum dolores nobis enim quidem excepturi, illum quos!</div>
            <ul class="postcard__tagbox">
                <li class="tag__item"><i class="fas fa-tag mr-2"></i>Podcast</li>
                <li class="tag__item"><i class="fas fa-clock mr-2"></i>55 mins.</li>
                <li class="tag__item play green">
                    <a href="#"><i class="fas fa-play mr-2"></i>Play Episode</a>
                </li>
            </ul>
        </div>
    </article>
    <article class="postcard dark yellow">
        <a class="postcard__img_link" href="#">
            <img class="postcard__img" src="https://picsum.photos/501/501" alt="Image Title" />
        </a>
        <div class="postcard__text">
            <h1 class="postcard__title yellow"><a href="#">Podcast Title</a></h1>
            <div class="postcard__subtitle small">
                <time datetime="2020-05-25 12:00:00">
                    <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                </time>
            </div>
            <div class="postcard__bar"></div>
            <div class="postcard__preview-txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi, fugiat
                asperiores inventore beatae accusamus odit minima enim, commodi quia, doloribus eius! Ducimus nemo
                accusantium maiores velit corrupti tempora reiciendis molestiae repellat vero. Eveniet ipsam adipisci
                illo iusto quibusdam, sunt neque nulla unde ipsum dolores nobis enim quidem excepturi, illum quos!</div>
            <ul class="postcard__tagbox">
                <li class="tag__item"><i class="fas fa-tag mr-2"></i>Podcast</li>
                <li class="tag__item"><i class="fas fa-clock mr-2"></i>55 mins.</li>
                <li class="tag__item play yellow">
                    <a href="#"><i class="fas fa-play mr-2"></i>Play Episode</a>
                </li>
            </ul>
        </div>
    </article>
</div>

<section class="light">
    <div class="container py-2">
        <div class="h1 text-center text-dark" id="pageHeaderTitle">My Cards Light</div>

        <article class="postcard light blue">
            <a class="postcard__img_link" href="#">
                <img class="postcard__img" src="https://picsum.photos/1000/1000" alt="Image Title" />
            </a>
            <div class="postcard__text t-dark">
                <h1 class="postcard__title blue"><a href="#">Podcast Title</a></h1>
                <div class="postcard__subtitle small">
                    <time datetime="2020-05-25 12:00:00">
                        <i class="fas fa-calendar-alt mr-2"></i>Mon, May 25th 2020
                    </time>
                </div>
                <div class="postcard__bar"></div>
                <div class="postcard__preview-txt">Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi,
                    fugiat asperiores inventore beatae accusamus odit minima enim, commodi quia, doloribus eius! Ducimus
                    nemo accusantium maiores velit corrupti tempora reiciendis molestiae repellat vero. Eveniet ipsam
                    adipisci illo iusto quibusdam, sunt neque nulla unde ipsum dolores nobis enim quidem excepturi,
                    illum quos!</div>
                <ul class="postcard__tagbox">
                    <li class="tag__item"><i class="fas fa-tag mr-2"></i>Podcast</li>
                    <li class="tag__item"><i class="fas fa-clock mr-2"></i>55 mins.</li>
                    <li class="tag__item play blue">
                        <a href="#"><i class="fas fa-play mr-2"></i>Play Episode</a>
                    </li>
                </ul>
            </div>
        </article>

    </div>
</section> -->
