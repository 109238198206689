import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, ActivatedRouteSnapshot, Params, RouterLink } from '@angular/router';
import { CommonModule, Location } from '@angular/common';
import { Router } from '@angular/router';
import { ConnectServerService } from '../../services/connect-server.service';
import { map, Observable, Subject } from 'rxjs';
import { User } from '../../interfaces/user';
import { Connect } from '../../classes/connect';
import { Menu } from '../../interfaces/menu';
import { ApiResponse } from '../../interfaces/api-response';
import { UrlService } from '../../services/url.service';
import { Store } from '@ngrx/store';
import { UserState } from '../../ngrx/user/user.reducer';
import * as UserActions from '../../ngrx/user/user.actions';
import { selectUserInfo } from '../../ngrx/user/user.selectors';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [MatIconModule, RouterLink, CommonModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'
})

export class NavbarComponent implements OnInit {

  user$!: Observable<User | null>;

  // public menu!: Menu[];
  menuNavbar$!: Observable<Menu[]>;
  routeParam: string | null = null;

  currentPage: string;
  email: string = '';

  constructor(public authService: AuthService, private router: Router,
    private connectServerService: ConnectServerService,
    private urlService: UrlService,
    private store: Store<{ user: UserState }>) {
    this.currentPage = this.getRootUrl();

    // si ottiene i risultati
    this.store.dispatch(UserActions.loadUserPermissions());

    // this.nickname$ = store.select(state => state.user.userInfo).pipe(
    //   map((user) => user ? user?.nickname : 'Profile')
    // );

    if (this.authService.isLoggedIn()) {
      // this.authService.getUser();
      // si richiama al server
      this.store.dispatch(UserActions.loadUserInfo());

    }
  }

  ngOnInit(): void {
    // this.user$ = this.authService.getInfoUserLogged();
    // si chiede le info
    this.user$ = this.store.select(selectUserInfo);
    this.menuNavbar$ = this.store.select(state => state.user.permissions).pipe(
      map((val) => val ? val.menu : []),
      map((menus: Menu[]) => menus.filter(menu => menu.level === 0))
    )
    // this.getMenu();
    // this.authService.getInfoMenu().subscribe(
    //   (val) => {
    //     this.getMenu();
    //   });

    this.urlService.getInfoCurrenPage().subscribe(
      (val: string) => {
        this.currentPage = val;
      }
    )
  }

  private getRootUrl(): string {
    if (typeof window !== "undefined") {
      const currentUrl: string = window.location.pathname;
      const urlSegments: string[] = currentUrl.split('/');
      return urlSegments[1]; // Il secondo elemento è la radice dell'URL
    } else {
      return "null";
    }
  }

  lastSubmenuVoice(order: number, submenu: Menu[]) {
    if(order < submenu.length) {
      return true;
    }
    else return false;
  }

  navigate(src: string) {
    this.router.navigate([src]);
    this.currentPage = src;
  }

  isCurrentPage(src: string): boolean {
    // console.log("current: ", this.currentPage);
    const srcLowerCase = src.toLowerCase().replace(/\s+/g, '');
    if (this.currentPage == "" && srcLowerCase == "home") {
      return true;
    }
    if ((this.currentPage == "archivioStorico" || this.currentPage == "archivioArtistico") && srcLowerCase == "archivio") {
      return true
    }
    if ((this.currentPage == "inventarioStorico" || this.currentPage == "inventarioArtistico") && srcLowerCase == "inventario") {
      return true
    }
    if ((this.currentPage == "nuovaSchedaStorica" || this.currentPage == "nuovaSchedaArtistica") && srcLowerCase == "nuovascheda") {
      return true
    }
    else return (this.currentPage == srcLowerCase);
  }

  logout() {
    this.authService.logoutUser();
  }

  goToSettings() {
    this.router.navigate(["settings"]);
  }

  goToPermissions() {
    this.router.navigate(["Autorizzazioni"]);
  }


  // getMenu() {
  //   this.connectServerService.getRequest(Connect.urlServerLaraApi, 'menu', {})
  //     .subscribe((val: ApiResponse<{ menu: Menu[] }>) => {
  //       // console.log("menu: ", val.data)
  //       if (val.data && val.data.menu) {
  //         // this.menuSubject.next(val.data.menu);
  //         this.menu = val.data.menu;
  //       }
  //     });

  // }

}
