import { Component } from '@angular/core';
import { ApiResponse } from '../../../interfaces/api-response';
import { Category } from '../../../interfaces/article';
import { Connect } from '../../../classes/connect';
import { InventoryService } from '../../../services/inventory.service';
import { ConnectServerService } from '../../../services/connect-server.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-inventory-artistic',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './inventory-artistic.component.html',
  styleUrl: './inventory-artistic.component.scss'
})
export class InventoryArtisticComponent {
  inventoryArtistic: any[] = [];

  categoryFilter = new FormControl<number | null>(null);
  categories: Category[] = [];

  constructor(private inventoryService: InventoryService, private connectServerService: ConnectServerService) {
    this.getInventoryHistorical();
    this.getCategories();
  }

  private getInventoryHistorical() {
    this.inventoryArtistic = this.inventoryService.inventoryHistorical;
    // Aggiungi dinamicamente la proprietà isOpen a ogni elemento
    this.inventoryArtistic.forEach(element => {
      element.isOpen = true; // Imposta il valore predefinito a false
    });
  }

  getInventoryFiltered() {
    console.log(this.categoryFilter.value);
  }

  private getCategories() {
    this.connectServerService.getRequest(Connect.urlServerLaraApi, 'categories', {})
      .subscribe(
        (val: ApiResponse<{ categories: Category[] }>) => {
          this.categories = val.data.categories;
        }
      )
  }

  openClose(element: any) {
    element.isOpen = !element.isOpen;
  }

  ngAfterViewChecked() {
    this.adjustContentHeight();
  }

  private adjustContentHeight() {
    const elements = document.querySelectorAll('.content-wrapper');
    elements.forEach((element: any) => {
      if (element.classList.contains('open')) {
        element.style.height = `${element.scrollHeight}px`;
      } else {
        element.style.height = '0';
      }
    });
  }
}
