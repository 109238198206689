import { Injectable } from '@angular/core';
import { Filters } from '../interfaces/filters';

@Injectable({
  providedIn: 'root'
})
export class ArchiveFilterService {

  filters: Filters = {
    title: null,
    categories: [],
    city: null,
    pointofinterest: null,
    date_type: null,
    date_article: null,
    yearFrom_article: null,
    yearTo_article: null,
    year_article: null,
  };

  constructor() { }

  saveValues(val: Filters) {
    this.filters = val;
  }

}
