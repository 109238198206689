import { Component } from '@angular/core';
import { FormControl, FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { ArticlesService } from '../../../services/articles.service';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Connect } from '../../../classes/connect';
import { ApiResponse } from '../../../interfaces/api-response';
import { Article } from '../../../interfaces/article';
import { Filters } from '../../../interfaces/filters';

import { FilterComponent } from './components/filter/filter.component';

@Component({
  selector: 'app-archive-historical',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    FilterComponent
  ],
  templateUrl: './archive-historical.component.html',
  styleUrl: './archive-historical.component.scss'
})
export class ArchiveHistoricalComponent {

  urlServerLara = Connect.urlServerLara;

  totalResults: number = 0;
  totalPages: number = 1;
  total: number = 0;
  itemsPerPage: number = 50;
  currentPageIndex: number = 1;

  filters!: Filters | string | null | undefined;
  type_filter = 0 // Ricerca testuale 0, ricerca non testuale != 0

  articles: Article[] = [];

  formControl = new FormControl('');
  options: string[] = [];
  filteredOptions: Observable<string[]> = new Observable;

  constructor(public articlesService: ArticlesService, private router: Router, private route: ActivatedRoute) { }

  navigate(id: number) {
    this.router.navigate(['scheda', id], { relativeTo: this.route })
  }

  ngOnInit() {
    let searchText = null;
    this.route.queryParamMap.subscribe(params => {
      searchText = params.get('text');
    });
    if (searchText && searchText != '') {
      this.searchArticles({ type: 0, obj_filter: searchText });
    }
    else {
      this.listaArticles();
    }

  }

  private listaArticles() {
    this.articlesService.getArticlesFromServer(this.itemsPerPage, this.currentPageIndex, this.type_filter, this.filters).
      subscribe((val: ApiResponse<{ listArticles: Article[], totalPages: number, totalResults: number }>) => {
        this.articles = val.data.listArticles;
        this.totalPages = val.data.totalPages;
        this.totalResults = val.data.totalResults;
        if(this.totalPages == 0) {
          this.totalPages += 1;
        }
      });
  }

  searchArticles(obj: { type: number, obj_filter: Filters | string | null | undefined }) {
    this.filters = obj.obj_filter;
    this.type_filter = obj.type;
    this.articlesService.getArticlesFromServer(this.itemsPerPage, 1, this.type_filter, this.filters).
      subscribe((val: ApiResponse<{ listArticles: Article[], totalPages: number, totalResults: number }>) => {
        this.articles = val.data.listArticles;
        this.totalPages = val.data.totalPages;
        this.totalResults = val.data.totalResults;
        if(this.totalPages == 0) {
          this.totalPages += 1;
        }
      });

  }


  setCurrentPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.articlesService.getArticlesFromServer(this.itemsPerPage, page, this.type_filter, this.filters).
        subscribe((val: ApiResponse<{ listArticles: Article[], totalPages: number }>) => {
          this.articles = val.data.listArticles;
          this.totalPages = this.totalPages;
          this.currentPageIndex = page;
        });
    }
  }

  previousPage() {
    if (this.currentPageIndex > 1) {
      this.articlesService.getArticlesFromServer(this.itemsPerPage, this.currentPageIndex - 1, this.type_filter, this.filters).
        subscribe((val: ApiResponse<{ listArticles: Article[], totalPages: number }>) => {
          this.articles = val.data.listArticles;
          this.totalPages = this.totalPages;
          this.currentPageIndex--;
        });
    }
  }

  nextPage() {
    if (this.currentPageIndex + 1 <= this.totalPages) {
      this.articlesService.getArticlesFromServer(this.itemsPerPage, this.currentPageIndex + 1, this.type_filter, this.filters).
        subscribe((val: ApiResponse<{ listArticles: Article[], totalPages: number }>) => {
          this.articles = val.data.listArticles;
          this.totalPages = this.totalPages;
          this.currentPageIndex++;
        });
    }
  }

  get pages(): Array<number> {
    // const pageCount = Math.ceil(this.articles.length / this.itemsPerPage);
    let totalPages = Array.from({ length: this.totalPages }, (_, index) => index + 1);
    if (this.totalPages > 4) {
      let reducedPages: Array<number> = [];
      if (this.currentPageIndex < 3) {
        return reducedPages = [1, 2, 3, 4];
      }
      else {
        if (this.totalPages - this.currentPageIndex < 3) {
          return reducedPages = [this.totalPages - 3, this.totalPages - 2, this.totalPages - 1, this.totalPages];
        }
        else {
          const current = this.currentPageIndex;
          return reducedPages = [current - 1, current, current + 1, current + 2];
        }
      }
    }
    else {
      if(totalPages.length == 0) {
        totalPages = [1];
      }
      return totalPages;
    }
  }

  // addOrRemoveCategory(insertCat: Category) {
  //   const categories = this.filterForm.get('categories')?.value;
  //   if (categories?.find((val: Category) => val.id === insertCat.id)) {
  //     this.filterForm.get('categories')?.reset();
  //     this.filterForm.get('categories')?.setValue(categories.filter((val: Category) => { val.id !== insertCat.id }));
  //   } else {
  //     this.filterForm.get('categories')?.value?.push(insertCat);
  //   }
  // }



}
