import { Component } from '@angular/core';
import { Connect } from '../../classes/connect';
import { ApiResponse } from '../../interfaces/api-response';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConnectServerService } from '../../services/connect-server.service';
import { PopupDialogService } from '../../services/popup-dialog.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-profile-settings',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  templateUrl: './profile-settings.component.html',
  styleUrl: './profile-settings.component.scss'
})
export class ProfileSettingsComponent {
  type1: string = 'password'
  type2: string = 'password'
  type3: string = 'password'
  toggled1: boolean = true;
  toggled2: boolean = true;
  toggled3: boolean = true;

  validPassword: boolean = true;
  equalPassword: boolean = true;
  errorMessage: boolean = false;

  generalForm = new FormGroup({
    name: new FormControl<string | null>(null, Validators.required),
    surname: new FormControl<string | null>(null, Validators.required),
    email: new FormControl<string | null>({ value: null, disabled: true }, [Validators.required, Validators.email]),
  })

  modifyPasswordForm = new FormGroup({
    current_password: new FormControl(null, Validators.required),
    password: new FormControl(null, Validators.required),
    password_confirmation: new FormControl(null, Validators.required),
  })

  constructor(private connectServerService: ConnectServerService, 
    private popupDialogService: PopupDialogService) { }

  ngOnInit() {}

  updateGeneral() {
    if (this.generalForm.valid) {
      let form_general = JSON.parse(JSON.stringify(this.generalForm.value));
    }
  }
  private actionUpdateGeneral(form_general: FormGroup) {
    this.connectServerService.postRequest(Connect.urlServerLaraApi, 'user/updateProfile',
      form_general
    ).subscribe((esito: ApiResponse<null>) => {
      this.popupDialogService.popupDialog(esito);
    })
  }

  checkPassword() {
    const password = this.modifyPasswordForm.get('password')?.value;
    const passwordRepeat = this.modifyPasswordForm.get('password_confirmation')?.value;
    const regex = /^(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":|<>])(?=.{8,})/;
    console.log('psw 1: ', password);
    console.log('psw 2: ', passwordRepeat)
    if(regex.test(password!)) {
      if(password === passwordRepeat) {
        this.equalPassword = true;
        this.validPassword = true;
        this.errorMessage = false;
        this.modifyPasswordForm.get("password")?.setErrors(null);
        this.modifyPasswordForm.get("password_confirmation")?.setErrors(null);
        return true;
      }
      else {
        this.equalPassword = false;
        this.validPassword = true;
        this.errorMessage = true;
        this.modifyPasswordForm.get("password")?.setErrors({invalid: true});
        this.modifyPasswordForm.get("password_confirmation")?.setErrors({invalid: true});
        return false;
      }
    }
    else {
      this.equalPassword = true;
      this.validPassword = false;
      this.errorMessage = true;
      this.modifyPasswordForm.get("password")?.setErrors({invalid: true});
      this.modifyPasswordForm.get("password_confirmation")?.setErrors(null);
      return false;
    }
  }

  seePassword(id: string) {
    if (id === 'password1') {
      if (this.type1 === 'password') {
        this.type1 = 'text';
        this.toggled1 = false;
      }
      else {
        this.type1 = 'password';
        this.toggled1 = true;
      }
    }
    else if (id === 'password2') {
      if (this.type2 === 'password') {
        this.type2 = 'text';
        this.toggled2 = false;
      }
      else {
        this.type2 = 'password';
        this.toggled2 = true;
      }
    }
    else {
      if (this.type3 === 'password') {
        this.type3 = 'text';
        this.toggled3 = false;
      }
      else {
        this.type3 = 'password';
        this.toggled3 = true;
      }
    }
  }

  saveNewPassword() {
    if (this.modifyPasswordForm.valid) {
      const obj_request: ApiResponse<any> = {
        code: 244,
        data: {},
        title: 'Info',
        message: 'Sei sicuro di voler cambiare la password di accesso?',
        obj_dialog: {
          disableClose: 1,
          obj_buttonAction:
          {
            action: 1,
            action_type: 2,
            label: 'Update',
            run_function: () => this.actionUpdatePassword()
          }
        }
      }
      this.popupDialogService.popupDialog(obj_request);
    }
  }

  checkGeneralForm() {
    const name = this.generalForm.get("name")?.value;
    const surname = this.generalForm.get("surname")?.value;

    if(name != '' && name != null) {
      this.generalForm.get("name")?.setErrors(null);
    }
    else {
      this.generalForm.get("name")?.setErrors({invalid: true});
    }

    if(surname != '' && surname != null) {
      this.generalForm.get("surname")?.setErrors(null);
    }
    else {
      this.generalForm.get("surname")?.setErrors({invalid: true});
    }
  }

  private actionUpdatePassword() {
    this.connectServerService.postRequest(Connect.urlServerLaraApi, 'user/resetPassword', {
      current_password: this.modifyPasswordForm.get('current_password')?.value,
      password: this.modifyPasswordForm.get('password')?.value,
      password_confirmation: this.modifyPasswordForm.get('password_confirmation')?.value,
    }).subscribe((esito: ApiResponse<null>) => {
      this.popupDialogService.popupDialog(esito);
      this.modifyPasswordForm.reset();
    })
  }
}
