import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { ArticlesService } from '../../../../services/articles.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Article } from '../../../../interfaces/article';
import { ApiResponse } from '../../../../interfaces/api-response';
import { ArticleFile } from '../../../../interfaces/image';
import { Connect } from '../../../../classes/connect';
import Viewer from 'viewerjs';
import { MatDialogRef } from '@angular/material/dialog';
import { ModalImageViewerComponent } from '../modal-image-viewer/modal-image-viewer.component';

@Component({
  selector: 'app-image-viewer',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './image-viewer.component.html',
  styleUrl: './image-viewer.component.scss'
})
export class ImageViewerComponent {

  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('image') image!: ElementRef;

  @Input() imageId!: number;

  urlServerLara = Connect.urlServerLara;

  zoomLevel: number = 1;
  zoomStep: number = 0.1;
  maxZoom: number = 2;
  minZoom: number = 0.3;
  isSmall: boolean = false;

  currentSrc: string | null = null;

  files: ArticleFile[] = [];
  article!: Article | null;
  //imageId!: number;
  activeImageIndex: number = 0;

  constructor(private articlesService: ArticlesService, private route: ActivatedRoute, private router: Router, private breakpointObserver: BreakpointObserver,
              private dialogRef: MatDialogRef<ModalImageViewerComponent>, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    console.log(this.imageId)
    this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.Handset])
      .subscribe(result => {
        this.isSmall = result.matches;
      });
    //this.route.params.subscribe(
    //  (params) => {
        //console.error('params: ', params['name'])
      //  this.imageId = parseInt(params['id']);
        this.articlesService.getFilesFromImage(this.imageId!).
        subscribe(
          (val: ApiResponse<{ listFilesFromImage: ArticleFile[] }>) => {
          this.files = val.data.listFilesFromImage;
          this.setCurrentImg();
          this.updateButtons();
        })
      }
    //);
  //}

  navigate() {
    this.router.navigate(['/archivio/scheda', this.files[0].refidarticle])
  }

  setCurrentImg() {
    this.currentSrc = this.files.find((val: ArticleFile) => {
      return val.id === this.imageId})?.src!;
    this.activeImageIndex = this.files.findIndex((val: ArticleFile) => {
      return val.id === this.imageId});
      console.log("Index: ", this.activeImageIndex);
  }

  nextPage() {
    if (this.activeImageIndex < this.files.length - 1) {
      this.activeImageIndex++;
      this.currentSrc = this.files[this.activeImageIndex].src;
    }
    this.updateButtons();
  }

  previousPage() {
    if (this.activeImageIndex > 0) {
      this.activeImageIndex--;
      this.currentSrc = this.files[this.activeImageIndex].src;
    }
    this.updateButtons();
  }

  updateButtons() {
    this.disableNextButton();
    this.disablePrevButton();
  }

  disableNextButton() {
    const button = document.getElementById('nextBtn') as HTMLButtonElement;
    if (this.activeImageIndex + 1 >= this.files.length) {
      // console.log('disabilita next');
      button.disabled = true;
    } else {
      // console.log('abilita next')
      button.disabled = false;
    }
  }

  disablePrevButton() {
    const button = document.getElementById('prevBtn') as HTMLButtonElement;
    if (this.activeImageIndex <= 0) {
      // console.log('disabilita prev');
      button.disabled = true;
    } else {
      button.disabled = false;
    }
  }

  zoomIn() {
    if (this.zoomLevel < this.maxZoom) {
      this.zoomLevel += this.zoomStep;
    }
  }

  zoomOut() {
    if (this.zoomLevel > this.minZoom) {
      this.zoomLevel -= this.zoomStep;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

}


