import { Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { authGuard } from './guards/auth.guard';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ContractsListComponent } from './cemetery/contracts-list/contracts-list.component';
import { ImportFileComponent } from './pages/settings/import-file/import-file.component';
import { ImageViewerComponent } from './pages/article/components/image-viewer/image-viewer.component';
import { ContractComponent } from './cemetery/contract/contract.component';
import { ForgotPasswordComponent } from './pages/password-management/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './pages/password-management/reset-password/reset-password.component';
import { ArchiveHistoricalComponent } from './pages/archive/archive-historical/archive-historical.component';
import { ArchiveArtisticComponent } from './pages/archive/archive-artistic/archive-artistic.component';
import { ArticleHistoricalComponent } from './pages/article/article-historical/article-historical.component';
import { ArticleArtisticComponent } from './pages/article/article-artistic/article-artistic.component';
import { ArticleHistoricalInsertComponent } from './pages/article/article-historical-insert/article-historical-insert.component';
import { ArticleArtisticInsertComponent } from './pages/article/article-artistic-insert/article-artistic-insert.component';
import { ArticleHistoricalUpdateComponent } from './pages/article/article-historical-update/article-historical-update.component';
import { ArticleArtisticUpdateComponent } from './pages/article/article-artistic-update/article-artistic-update.component';
import { InventoryHistoricalComponent } from './pages/inventory/inventory-historical/inventory-historical.component';
import { InventoryArtisticComponent } from './pages/inventory/inventory-artistic/inventory-artistic.component';
import { ProfileSettingsComponent } from './pages/profile-settings/profile-settings.component';
import { AuthorizationsComponent } from './pages/authorizations/authorizations.component';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent, canActivate: [authGuard] },

  // storico
  { path: 'archivioStorico', component: ArchiveHistoricalComponent, canActivate: [authGuard] },
  { path: 'archivioStorico/scheda/:id', component: ArticleHistoricalComponent, canActivate: [authGuard] },
  { path: 'nuovaSchedaStorica', component: ArticleHistoricalInsertComponent, canActivate: [authGuard] },
  { path: 'modificaSchedaStorica/:id', component: ArticleHistoricalUpdateComponent, canActivate: [authGuard] },
  { path: 'inventarioStorico', component: InventoryHistoricalComponent },

  // artistico
  { path: 'archivioArtistico', component: ArchiveArtisticComponent, canActivate: [authGuard] },
  { path: 'archivioArtistico/scheda/:id', component: ArticleArtisticComponent, canActivate: [authGuard] },
  { path: 'nuovaSchedaArtistica', component: ArticleArtisticInsertComponent, canActivate: [authGuard] },
  { path: 'modificaSchedaArtistica/:id', component: ArticleArtisticUpdateComponent, canActivate: [authGuard] },
  { path: 'inventarioArtistico', component: InventoryArtisticComponent },

  // profilo ed accesso
  { path: 'login', component: LoginComponent, canActivate: [authGuard] },
  { path: 'settings', component: ProfileSettingsComponent },
  { path: 'richiediAccesso', component: SignupComponent },

  // { path: 'archivio/scheda/imageviewer/:id', component: ImageViewerComponent, canActivate: [authGuard] },
  { path: 'passwordDimenticata', component: ForgotPasswordComponent },
  { path: 'reimpostaPassword/:token', component: ResetPasswordComponent },
  { path: 'chisiamo', component: AboutUsComponent, canActivate: [authGuard] },
  { path: 'contatti', component: ContactsComponent, canActivate: [authGuard] },
  { path: 'accessonegato', component: AccessDeniedComponent },
  { path: 'impostazioni/importazioneFiles', component: ImportFileComponent, canActivate: [authGuard] },
  { path: 'autorizzazioni', component: AuthorizationsComponent },
  { path: '**', redirectTo: 'home' },

  // { path: 'modificascheda', component: ArticleUpdateComponent, canActivate: [authGuard] },
  // { path: 'cimitero', component: ContractsListComponent, canActivate: [authGuard] },
  // { path: 'cimitero/contratto/:id', component: ContractComponent, }
];
