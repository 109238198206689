<div class="container p-1 p-md-3 p-lg-5" style="min-height: 80%; min-width: 350px; max-width: 1400px;">
    <div class="row p-2 justify-content-center align-items-center text-center">
        <div class="col-12" style="position: relative;">
            <img src="assets/img/contactBar.png" class="d-none d-lg-block img-flex mx-auto"
                style="width: 90%; height: auto;">
            <span class="d-none d-lg-block" style="font-weight: bold; color: white; font-size: x-large;
                    position: absolute; top: 62%; left: 40%;">
                GESTIONE AUTORIZZAZIONI
            </span>
        </div>
        <div class="col-8 text-center">
            <div class="d-lg-none" style="background-color: #1E73BE; border-radius: 15px;">
                <span style="font-weight: bold; font-size: x-large; color: white;">
                    GESTIONE AUTORIZZAZIONI
                </span>   
            </div>
        </div>
        <div class="col-12 col-lg-10 mt-2">
            <form [formGroup]="usersForm" (ngSubmit)="onSubmit()">
                <table mat-table [dataSource]="usersArray.controls" class="mat-elevation-z8">

                    <!-- Position Column -->
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Nome </th>
                        <td mat-cell *matCellDef="let page"> {{page.get('user_name')?.value}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container matColumnDef="surname">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Cognome </th>
                        <td mat-cell *matCellDef="let page"> {{page.get('user_surname')?.value}} </td>
                    </ng-container>

                    <!-- Description Column -->
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Motivo Richiesta </th>
                        <td mat-cell *matCellDef="let page"> {{page.get('description')?.value}} </td>
                    </ng-container>

                    <!-- Weight Column -->
                    <ng-container matColumnDef="access">
                        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Accesso </th>
                        <td mat-cell *matCellDef="let page; let i = index" [formGroup]="page" style="text-align: center;">
                            <input class="form-check-input" type="checkbox" role="switch" formControlName="access"
                            [checked]="page.get('access')?.value === 1" (change)="onCheckboxChange($event, 'access', i)">
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </form>
        </div>

        <!-- <div class="col-12 mt-4 text-center">
          <button type="button" class="btn btn-outline-dark" (click)="onSubmit()">
            Submit
          </button>          
        </div> -->
    </div>
</div>