<div class="container-fluid" style="min-height: 80%; min-width: 350px;">
  <div class="row">
    <div class="col-12 mx-0 px-0">
      <div id="carousel" class="carousel slide carousel-fade" data-bs-ride="carousel" data-interval="5000">
        <div class="carousel-inner">
          <div class="container container_bar">
            <div class="row justify-content-center">
              <div class="col-12 col-lg-8">
                <div class="input-group mb-3" style="height: 50px;">
                  <button class="btn btn-dark searchButton dropdown-toggle d-none d-md-block" type="button" data-bs-toggle="dropdown" aria-expanded="false" style="width: 158px;">
                    {{choosenArchive.name}}
                  </button>
                  <button class="btn btn-dark searchButton dropdown-toggle d-block d-md-none" type="button" data-bs-toggle="dropdown" aria-expanded="false" 
                    style="border-top-left-radius: 10px; border-bottom-left-radius: 10px;"></button>
                  <ul class="dropdown-menu">
                    <li class="listMenu" (click)="changeArchive()"><a class="dropdown-item">Archivio Storico</a></li>
                    <li class="listMenu" (click)="changeArchive()"><a class="dropdown-item">Archivio Artistico</a></li>
                  </ul>
                  <input type="text" class="form-control searchBar" style="opacity: 0.9;" placeholder="Ricerca in {{choosenArchive.name}}" [formControl]="search">
                  <button class="btn btn-dark searchButton" type="button" id="button-addon2" (click)="searchArchive()">Cerca</button>
                </div>
              </div>
            </div>
          </div>
          <div *ngFor="let item of carousel; let i = index">
            <div class="carousel-item active" [class.active]="i === 0">
              <img [src]="urlServerLara + item.src" class="d-block w-100">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row align-items-center py-1">
    <div *ngFor="let card of cards" class="col-12 col-md-6 py-4">
      <app-card [obj_card]="card"></app-card>
    </div>
  </div>
</div>


<div class="container-fluid">
  <div class="row align-items-center py-4" style="background-color: #1E73BE;">
    <div class="col-12 col-sm-6 col-md-3 text-center">
      <a class="text-decoration-none" href="https://www.estotemisericordes.it/">
        <img class="navbar-logo redirect-logo" src="assets/img/estote.png">
        <div class="mt-2">
          <div style="color: white; font-weight: bold;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
            Fondazione Estote <br> Misericordes <br> Ambulatori e Funebre
          </div>
        </div>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-3 text-center">
      <a class="text-decoration-none" href="http://www.misericordia.net/">
        <img class="navbar-logo redirect-logo" src="assets/img/logo.png">
        <div class="mt-2">
          <div class="justify-content-center" style="color: white; font-weight: bold; text-decoration: solid;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
            Misericordia <br> Borgo San Lorenzo
          </div>
        </div>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-3 text-center">
      <a class="text-decoration-none" href="https://www.fratres.it/">
        <img class="navbar-logo redirect-logo" src="assets/img/fratres.png">
        <div class="mt-2">
          <div style="color: white; font-weight: bold;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
            Fratres <br> Donatori di sangue
          </div>
        </div>
      </a>
    </div>
    <div class="col-12 col-sm-6 col-md-3 text-center">
      <a class="text-decoration-none" href="http://protezionecivile.uc-mugello.fi.it/">
        <img class="navbar-logo redirect-logo" src="assets/img/protezioneCivile.png">
        <div class="mt-2">
          <div class="text-decoration-none" style="color: white; font-weight: bold;
              text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;">
            Protezione Civile <br> Mugello
          </div>
        </div>
      </a>
    </div>
  </div>
</div>