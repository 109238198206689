import { Component } from '@angular/core';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs';
import { Connect } from '../../../classes/connect';
import { ConnectServerService } from '../../../services/connect-server.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { PopupDialogService } from '../../../services/popup-dialog.service';
import { ApiResponse } from '../../../interfaces/api-response';
import {MatButtonModule} from '@angular/material/button';

@Component({
  selector: 'app-import-file',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatInputModule, MatButtonModule],
  templateUrl: './import-file.component.html',
  styleUrl: './import-file.component.scss'
})
export class ImportFileComponent {

  constructor(private connectServerService: ConnectServerService, private popupDialogService: PopupDialogService) { }

  ngOnInit(): void {
  }


  uploadFiles(event: any): void {
    const files: File[] = event.target.files;
    let messaggio = '';
    for (const file of files) {
      if (file) {
        if (file.size < Connect.sizeFileExcel) {
          const formData = new FormData();
          formData.append('allegato', file);
          const upload$ = this.connectServerService
            .postRequest(Connect.urlServerLaraApi, 'importRegProvCit', formData);
          upload$
            .subscribe(
              (res: ApiResponse<any>) => {
                this.popupDialogService.popupDialog(res)
              }
            );
        } else {
          messaggio = messaggio + 'Impossibile caricare il file ' + file.name
            + '\n';
        }
      }
    }
    if (messaggio.length > 0) {
      alert(messaggio + 'Dimensione consentita per ogni file: ' + Connect.str_sizeFileExcel + '\n');
    }
  }

}
