import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CardComponent } from '../../components/card/card.component';
import { ConnectServerService } from '../../services/connect-server.service';
import { Connect } from '../../classes/connect';
import { ApiResponse } from '../../interfaces/api-response';
import { Card, Carousel } from '../../interfaces/home';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CommonModule,
    CardComponent,
    ReactiveFormsModule
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent {

  search = new FormControl<string | null>(null);
  choosenArchive: {name: string, id: number} = {name: 'Archivio Storico', id: 1};
  carousel: Carousel[] = [];

  urlServerLara = Connect.urlServerLara;

  cards: Card[] = [];
  //img: any[];
  query: string = '';

  constructor(private connectService: ConnectServerService, private router: Router) {
    this.serverRequest();
    //getHomeSlides()
    // this.img = [
    //   { path: 'assets/img/misericordia.jpg', name: 'misericordia' },
    //   { path: 'assets/img/misericordia1.jpg', name: 'misericordia1' },
    //   { path: 'assets/img/misericordia2.jpg', name: 'misericordia2' },
    // ]

    //Prendi le cards dal server
    //getHomeCards()
    // this.cards = [
    //   { img: 'assets/img/card1.png', src: '', title: 'Archivio Magistrato', description: 'Lorem Ipsum è considerato il testo segnaposto standard sin dal sedicesimo secolo, quando un anonimo tipografo prese una cassetta di caratteri e li assemblò per preparare un testo campione. È sopravvissuto non solo a più di cinque secoli, ma anche al passaggio alla videoimpaginazione, pervenendoci sostanzialmente inalterato.', id: '1' },
    //   { img: 'assets/img/card2.png', src: '', title: 'Archivio Cimitero', description: 'Esistono innumerevoli variazioni dei passaggi del Lorem Ipsum, ma la maggior parte hanno subito delle variazioni del tempo, a causa dell’inserimento di passaggi ironici, o di sequenze casuali di caratteri palesemente poco verosimili', id: '2' },
    //   { img: 'assets/img/card3.png', src: '', title: 'Archivio Misericordia', description: 'Richard McClintock, professore di latino al Hampden-Sydney College in Virginia, ha ricercato una delle più oscure parole latine, consectetur, da un passaggio del Lorem Ipsum e ha scoperto tra i vari testi in cui è citata, la fonte da cui è tratto il testo, le sezioni 1.10.32 and 1.10.33 del "de Finibus Bonorum et Malorum" di Cicerone. Questo testo è un trattato su teorie di etica, molto popolare nel Rinascimento.', id: '3' },
    //   { img: 'assets/img/card4.png', src: '', title: 'Archivio consiglio', description: 'Se si decide di utilizzare un passaggio del Lorem Ipsum, è bene essere certi che non contenga nulla di imbarazzante. In genere, i generatori di testo segnaposto disponibili su internet tendono a ripetere paragrafi predefiniti, rendendo questo il primo vero generatore automatico su intenet.', id: '4' },
    // ]

    // if(localStorage.getItem('Token')) {
    //   console.log(localStorage.getItem('Token'));
    // }
  }

  searchArchive() {
    // this.router.navigate(['archivio', { queryParams: { text: this.search.value}}]);
    if(this.choosenArchive.id == 1) {
      this.router.navigate(['/archivioStorico'], { queryParams: { text: this.search.value } }); 
    }
    else {
      this.router.navigate(['/archivioArtistico'], { queryParams: { text: this.search.value } }); 
    }
  }

  serverRequest() {
    this.connectService.getRequest(Connect.urlServerLaraApi, 'home', {}).
    subscribe((val: ApiResponse<{cards: Card[], carousel: Carousel[]}>) => {
      // console.log("data: ", val.data)
      if(val.data && val.data.carousel) {
        this.carousel = val.data.carousel;
      }
      if(val.data && val.data.cards) {
        this.cards = val.data.cards;
      }

    });
  }

  submit(query: string): void {
    this.query = query;
  }

  changeArchive() {
    if(this.choosenArchive.name == 'Archivio Storico') {
      this.choosenArchive.name = 'Archivio Artistico'
      this.choosenArchive.id = 2;
    }
    else {
      this.choosenArchive.name = 'Archivio Storico'
      this.choosenArchive.id = 1;
    }
    console.log(this.choosenArchive)
  }

}
